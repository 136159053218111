import { Page, palette } from "../ui";

import { BlogHeader } from "../blog/header";
import { BlogPreview } from "../blog/preview";
import { ContentContainer } from "../ui/layouts";
import { blogPosts } from "../blog";
import styled from "styled-components";

const BlogGrid = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 16px 0px;
  grid-gap: 16px;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 16px;
  }
`;

export function BlogListPage() {
  return (
    <Page backgroundColor={palette.BLOGPAGE_BACKGROUND}>
      <BlogHeader value="Townley Blog" />
      <ContentContainer fixed>
        <BlogGrid>
          {blogPosts.map((post) => (
            <BlogPreview post={post} key={post.slug} />
          ))}
        </BlogGrid>
      </ContentContainer>
    </Page>
  );
}
