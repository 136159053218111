import styled from "styled-components";

import { BlogPost } from "../blog/types";
import { Page, palette } from "../ui";

import { BlogHeader } from "../blog/header";
import { ContentContainer } from "../ui/layouts";

interface Props {
  post: BlogPost;
}

export function BlogDetailPage({ post }: Props) {
  return (
    <Page backgroundColor={palette.BLOGPAGE_BACKGROUND}>
      <BlogHeader value={post.title} />
      <ContentContainer fixed>
        <Subtitle>
          Published on {post.publication_date.toLocaleString()}
        </Subtitle>
        {post.component}
      </ContentContainer>
    </Page>
  );
}

const Subtitle = styled.h4`
  font-weight: 200;
  font-size: 1rem;
  margin: 16px 0px;
  @media (max-width: 768px) {
    padding: 0px 16px;
  }
`;
