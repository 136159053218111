import { ReactNode } from "react";
import styled from "styled-components";

import {
  Navigation,
  DESKTOP_NAVIGATION_WIDTH,
  MOBILE_NAVIGATION_HEIGHT,
} from "../navigation";

interface Props {
  backgroundColor?: string;
  fixed?: boolean;
  children: ReactNode;
  variant?: "default" | "minimal";
}

function MinimalLayout({ children }: Props) {
  return (
    <div>
      <h1>Minimal Layout</h1>
      {children}
    </div>
  );
}

const DefaultContainer = styled.div`
  background-color: black;
  @media (min-width: 768px) {
    display: flex;
  }
`;
interface ContentProps {
  backgroundColor?: string;
  fixed?: boolean;
}
const ContentWrapper = styled.div<ContentProps>`
  background-color: ${(props) => props.backgroundColor ?? "inherit"};
  @media (min-width: 768px) {
    width: calc(100% - ${DESKTOP_NAVIGATION_WIDTH});
    max-height: 100vh;
    overflow-y: scroll;
  }
  @media (max-width: 768px) {
    height: calc(100vh - ${MOBILE_NAVIGATION_HEIGHT});
    overflow-y: scroll;
  }
`;

export const ContentContainer = styled.div<ContentProps>`
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  text-align: left;

  ${(props) => props.fixed && "max-width: 1280px"};
  ${(props) =>
    props.fixed &&
    "display: flex; align-items: flex-start; justify-content: flex-start; flex-direction: column;"};
`;

export function Page({ backgroundColor, children, fixed, variant }: Props) {
  switch (variant) {
    case "minimal":
      return <MinimalLayout>{children}</MinimalLayout>;
    default:
      return (
        <DefaultContainer>
          <Navigation />
          <ContentWrapper backgroundColor={backgroundColor} fixed={fixed}>
            <ContentContainer fixed={fixed}>{children}</ContentContainer>
          </ContentWrapper>
        </DefaultContainer>
      );
  }
}
