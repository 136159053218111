import styled from "styled-components";

import { Page, palette } from "../ui";
import { entries } from "../portfolio/entries";
import { PortfolioItem } from "../portfolio/types";

const PageTitle = styled.h1`
  box-sizing: border-box;
  color: white;
  font-size: 3rem;
  text-align: left;
  font-weight: 200;
  width: 100%;
  @media (max-width: 768px) {
    padding-left: 24px;
  }
`;

const Title = styled.h2`
  box-sizing: border-box;
  padding-left: 8px;
  font-size: 28px;
  margin: 0;
  font-weight: 200;
`;

const Subtitle = styled.h4`
  box-sizing: border-box;
  padding-left: 8px;
  font-size: 16px;
  margin: 0;
  font-weight: 200;
`;

const PortfolioGrid = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  box-sizing: border-box;
  padding-bottom: 16px;
  background-color: white;
  border-radius: 8px;
  @media (max-width: 768px) {
    margin: 16px;
  }
`;

const Image = styled.img`
  box-sizing: border-box;
  width: 100%;
  height: 240px;
  object-fit: cover;
  border-radius: 8px 8px 0px 0px;
`;

const DetailsList = styled.ul`
  box-sizing: border-box;
  margin-top: 8px;
`;
const Detail = styled.li`
  rox-sizing: border-box;
  font-weight: 200;
  margin-bottom: 8px;
`;

function PortfolioGridItem({ entry }: { entry: PortfolioItem }) {
  return (
    <Card>
      <Image src={entry.image} />
      <Title>{entry.title}</Title>
      <Subtitle>{entry.subtitle}</Subtitle>
      <DetailsList>
        {entry.bullets.map((bullet) => (
          <Detail key={bullet}>{bullet}</Detail>
        ))}
      </DetailsList>
    </Card>
  );
}
export function PortfolioPage() {
  return (
    <Page backgroundColor={palette.PORTFOLIOPAGE_BACKGROUND} fixed>
      <PageTitle>Portfolio</PageTitle>
      <PortfolioGrid>
        {entries.map((entry) => (
          <PortfolioGridItem entry={entry} key={entry.title} />
        ))}
      </PortfolioGrid>
    </Page>
  );
}
