import styled from "styled-components";

import { palette } from "../ui";

export const DESKTOP_NAVIGATION_WIDTH = "128px";
export const MOBILE_NAVIGATION_HEIGHT = "48px";

const NavigationContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: black;

  @media (max-width: 768px) {
    height: ${MOBILE_NAVIGATION_HEIGHT};
    width: 100vw;
  }

  @media (min-width: 768px) {
    width: ${DESKTOP_NAVIGATION_WIDTH};
    height: 100vh;

    align-items: center;
    flex-direction: column;
  }
`;

interface LinkProps {
  backgroundColor: string;
  color: string;
}

const Link = styled.a<LinkProps>`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  padding: 8px;
  box-sizing: border-box;
  text-decoration: none;

  @media (max-width: 768px) {
    height: 100%;
    width: 168px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 768px) {
    width: ${DESKTOP_NAVIGATION_WIDTH};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export function Navigation() {
  return (
    <NavigationContainer>
      <Link
        color={palette.LIGHT_TEXT}
        backgroundColor={palette.HOMEPAGE_BACKGROUND}
        href="/"
      >
        Home
      </Link>
      <Link
        backgroundColor={palette.ABOUTPAGE_BACKGROUND}
        color={palette.DARK_TEXT}
        href="/about"
      >
        About
      </Link>
      <Link
        backgroundColor={palette.PORTFOLIOPAGE_BACKGROUND}
        color={palette.LIGHT_TEXT}
        href="/portfolio"
      >
        Portfolio
      </Link>
      <Link
        backgroundColor={palette.CONTACTPAGE_BACKGROUND}
        color={palette.DARK_TEXT}
        href="/contact"
      >
        Contact
      </Link>
      <Link
        backgroundColor={palette.BLOGPAGE_BACKGROUND}
        color={palette.DARK_TEXT}
        href="/blog"
      >
        Blog
      </Link>
    </NavigationContainer>
  );
}
