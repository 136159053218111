import _ from "lodash";
import { useState } from "react";
import {
  Box,
  Camera,
  Scene,
  useConnection,
  useSharedEvent,
  useSharedState,
  XYZObject,
} from "gamehook";

function Obstacles() {
  return <></>;
}

const ACCELERATION_RATE = -0.005;
const INITIAL_VELOCITY = {
  x: 0.1,
  y: 0.1,
  z: 0,
};

const GAME_SIZE = 10;
const LENGTH = 100;

function Boundaries({ positions }: { positions: XYZObject[] }) {
  return (
    <>
      {positions.map((p: XYZObject) => (
        <Box
          position={p}
          key={`${p.x}${p.y}`}
          collides
          rotation={{ x: (p.x % 7) / 100, y: (p.x % 7) / 100, z: 0.02 }}
        />
      ))}
    </>
  );
}

function generatePositions(): XYZObject[] {
  return _.range(0, LENGTH).reduce((acc: XYZObject[], i) => {
    acc.push({
      x: i * 1.1 + 2,
      y: 0 - GAME_SIZE - Math.random(),
      z: 0,
    });
    acc.push({
      x: i * 1.1 + 2,
      y: GAME_SIZE + Math.random(),
      z: 0,
    });
    if (Math.random() > 0.1) {
      acc.push({
        x: i * 1.1 + 2,
        y: _.random(0 - GAME_SIZE, GAME_SIZE),
        z: 0,
      });
    }
    return acc;
  }, []);
}

type Interaction = "flap" | "restart";
function Game({ clientId, lobbyId }: { clientId: string; lobbyId: string }) {
  const connection = useConnection({
    clientId,
    lobbyId,
  });
  const [gameOver, setGameOver] = useSharedState(
    "game-over",
    connection,
    false
  );
  const [positions, setPositions] = useSharedState<XYZObject[]>(
    "obstacle-positions",
    connection,
    generatePositions()
  );
  const [velocity, setVelocity] = useState<XYZObject>(INITIAL_VELOCITY);

  const interaction = useSharedEvent<Interaction>(
    "player-interaction",
    connection
  );

  interaction.listen((_message) => {
    flapCube();
  });

  const handleKeypress = () => {
    interaction.emit("flap");
    flapCube();
  };

  const flapCube = () => {
    setVelocity({ x: INITIAL_VELOCITY.x, y: INITIAL_VELOCITY.y, z: 0 });
  };

  const handleCollision = () => {
    setGameOver(true);
  };

  const resetGame = () => {
    setVelocity(INITIAL_VELOCITY);
    setPositions(generatePositions());
    setGameOver(false);
  };

  return (
    <Scene>
      {!gameOver && (
        <>
          <Box
            rotation={{ x: 0.02, y: 0.02, z: 0.02 }}
            acceleration={{ x: 0, y: ACCELERATION_RATE, z: 0 }}
            velocity={velocity}
            material={{
              type: "basic",
              color: 0xffaa00,
            }}
            onKeypress={handleKeypress}
            onCollision={handleCollision}
            id="flappyCube"
            syncProperties={{
              connection,
              id: "flappyCube",
              properties: ["position"],
              frequency: 500,
            }}
          />
          <Camera follow={gameOver ? "loserCube" : "flappyCube"} />
        </>
      )}
      {gameOver && <Box onKeypress={resetGame} id="loserCube" />}
      <Boundaries positions={positions} />
      <Obstacles />
    </Scene>
  );
}

export function CubeGoesUpGame() {
  const params = new URLSearchParams(window.location.search);
  const clientId = params.get("user");
  const lobbyId = params.get("lobby");
  if (!clientId || !lobbyId) {
    return (
      <p>
        Please add your user and lobby to the URL search bar. Eg
        mysite.com?user=myname&lobby=mylobby
      </p>
    );
  }
  return <Game clientId={clientId} lobbyId={lobbyId} />;
}
