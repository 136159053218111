import styled from "styled-components";
import Forest from "../assets/forest.jpg";

const HeaderBackground = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)),
    url("${Forest}");
  background-size: cover;
  background-position: center 60%;
  width: 100%;
  height: 40vh;

  color: white;
  font-weight: 200;
  display: flex;
  align-items: flex-end;
  padding: 24px;
  box-sizing: border-box;
`;
const BlogTitle = styled.h1`
  font-weight: 200;
`;

export function BlogHeader({ value }: { value: string }) {
  return (
    <div>
      <HeaderBackground>
        <BlogTitle>{value}</BlogTitle>
      </HeaderBackground>
    </div>
  );
}
