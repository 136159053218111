import { Paragraph, SectionHeader } from "../../ui";
import directoryListing from "./directoryListing.png";

export function GettingBetterAtVim() {
  return (
    <div>
      <Paragraph>
        Today at work, the Vim Club (yes, we have a vim club! Come work for us){" "}
        <b>
          <i>
            (2021 edit: sadly, the company has gone out of business, and is
            obviously no longer an employment option)
          </i>
        </b>{" "}
        had a meeting where one of our members let me pester him with questions
        for almost an hour. Throughout the process, I learned a lot about my
        code editor of choice, and I wanted to share some of that newfound
        knowledge here.
      </Paragraph>
      <SectionHeader>Why Vim?</SectionHeader>
      <Paragraph>
        I've been using vim for almost ten years, and my ability to use the
        editor has grown with my skills as a developer. So in some ways, it's
        always felt like Vim's grown with me, like a scary black box of a
        Pokemon. The struggles of "configuring Vim to get a nice feature that
        VSCode gives you without any effort" also always reminded me of the
        early days of setting up Linux computers because I was too cheap to buy
        another windows license.
      </Paragraph>
      <Paragraph>
        But even beyond those impractical considerations, I love using the tool.
        The ability to tile terminal and editor windows together on my monitor
        has always lent itself to a flexible workflow, no matter what I've
        worked on. I could run a react server alongside my code and see errors
        as they arose, or flip back and forth between running python tests. The
        editor / terminal fluidity does for my brain what "not having to move to
        the mouse as much" does for my hands.
      </Paragraph>
      <Paragraph>
        Even so, vim is a beast to learn, and BEAST to master. I learn new
        things about it weekly, and this week I learned more than most weeks. So
        with that, let's chat a bit about one topic that we covered: tiling
        windows.
      </Paragraph>

      <SectionHeader>iTerm2? Tmux? Who needs 'em?</SectionHeader>
      <Paragraph>
        Most of my colleagues in the vim club are better vim-er than I am.
        Colleague A's workflow is similar in that he values multiple windows and
        seamless terminal interactions, but he prefers vim's built-in panes to
        using something like iterm2 or tmux to have multiple tiled windows.
        Colleague B agrees, but takes it one step further in using vim's tabbed
        interface rather than iterm2 tabs on OSX.
      </Paragraph>
      <Paragraph>
        One thing that I thought I'd miss from this flow is the ability to
        browse files. The directory browser that comes included in vim has
        always jumped out at me confusingly, only when I hit enter prematurely
        and entered into a directory when I wanted a file. I browse files by
        having a terminal open, and running cd and ls commands to navigate the
        file directories. When I've found a filepath I like, I open that in vim.
      </Paragraph>
      <Paragraph>That workflow has a few problems to it though:</Paragraph>
      <ul>
        <li>
          You lose the power of vim buffers, as the only buffer that can be
          shared among your windows is the system clipboard
        </li>
        <li>
          You can't open the same file in more than one window without
          encountering .swp file problems (useful when you want to have two
          parts of a file side-by-side)
        </li>
        <li>
          You're not leveraging vim's built-in tool for doing so seamlessly
        </li>
        <li>That tool is actually much better than I gave it credit for</li>
      </ul>
      <SectionHeader>Directory Browsing Within Vim</SectionHeader>
      <Paragraph>
        When you open a file in vim, it opens that file in vim. Duh. But
        sometimes you open a directory in vim, and get a confusing window. In
        less than 20 minutes, the Vim club helped me go from being confused and
        irritated by the typo that made me open a directory, to turning that
        into an indispensable part of my workflow.
      </Paragraph>
      <Paragraph>
        When you open a directory in <code>vim</code>, it opens a netrw window.
        Netrw ("Network oriented reading, writing, and browsing") is a program
        for browsing directories directly within your vim session. When you run
        vim . in your current directory, you get a file explorer into that
        directory.
      </Paragraph>
      <img
        alt="My screen, showing the file browser result of a NetRW command"
        src={directoryListing}
      />
      <Paragraph>
        Note that your directory structure might look a bit different in layout.
        You can cycle through layout options in netrw by pressing lowercase i
        (you can also disable that menu bar by pressing uppercase I).
      </Paragraph>
      <Paragraph>
        I'm a fan of the tree layout, which mirrors the tree util I use in my
        terminal, or the popular NERDTree vim plugin. I set this to be my
        default option by adding <code>let g:netrw_liststyle= 3</code> to my
        .vimrc.
      </Paragraph>
      <Paragraph>
        The netrw commands within vim are a whole mess of things to sort through
        and determine preferences for. But I found that just knowing about
        netrw, and what was happening when you open a directory in vim made file
        browsing within the editor a lot less intimidating. That, in turn, made
        me look more favorably on using the built-in pane switching (done by
        typing :vsp or :sp in an open vim instance) rather than iTerm2 or Tmux.{" "}
      </Paragraph>
      <SectionHeader>
        Keep your friends close, and your cursor closer
      </SectionHeader>
      <Paragraph>
        One of my favorite additions to my .vimrc to come out of the meeting was{" "}
        <code>set scrolloff=5</code>
      </Paragraph>

      <Paragraph>
        This setting keeps your cursor the specified number of lines from the
        top or bottom of the open window, as long as the file supports it. This
        means that if your cursor is at the bottom of the screen, you know that
        it's at the bottom of the file. And since my eyes tend to follow the
        cursor, I find it convenient to keep the cursor ever so slightly closer
        to the center of the screen.
      </Paragraph>
      <Paragraph>
        One of my colleagues takes this principle to the max, by setting
        scrolloff to be equal to 999, so his cursor is always dead center in the
        screen.
      </Paragraph>
      <Paragraph>
        While we were discussing cursor positioning, I managed to teach the vim
        experts a quick trick: somehow, they hadn't head of z(t | z | b) while
        in an editor. Hitting zt moves your cursor to the top of the screen.
        Hitting zb moves it to the bottom, and hitting zz centers it on the
        screen. The top and bottom commands play nicely particularly well with
        the aforementioned scrolloff setting. I find "send my cursor to 5 rows
        from the top of the screen" to be more visually ergonomic than "send my
        cursor to the tippy top of the screen"
      </Paragraph>
      <SectionHeader>More to learn</SectionHeader>
      <Paragraph>
        As with anyone, there are glaring gaps in my vim understanding. I leave
        the editor to search code using silver search.I don't utilize buffers
        efficiently (though now that I'm planning to stay in one vim session
        going forward, I'm hopeful that this can now change). And my .vimrc is a
        mess that I don't fully understand. But with this week's new vim
        abilities in hand, I'm excited to continue learning!
      </Paragraph>
      <Paragraph>
        PS: One resource I'm excited to begin working through as I do:{" "}
        <a
          href="https://www.barbarianmeetscoding.com/series/exploring-vim/"
          target="_blank"
          rel="noreferrer"
        >
          Exploring Vim
        </a>
      </Paragraph>
    </div>
  );
}
