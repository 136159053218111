import { Paragraph, Link } from "../ui";

export function SolarPunk() {
  return (
    <div>
      <Paragraph>
        This isn't really a blog post, I'm just writing this down to remember
        it. I just read through this article on &nbsp;
        <Link href="https://www.messynessychic.com/2021/06/10/inside-the-imaginarium-of-a-solarpunk-architect/">
          Solar Punk
        </Link>
        . I'd never heard of it before, but found the aesthetic interesting and
        particularly unique compared to SteamPunk culture.
      </Paragraph>
    </div>
  );
}
