import { Paragraph, SectionHeader } from "../../ui";
import auth from "./auth.png";
import name from "./name.png";

export function FunWithWASM() {
  return (
    <div>
      <Paragraph>
        Today's a professional development day at work, so I'm going to work on
        improving my Rust!
      </Paragraph>
      <Paragraph>
        I've been re-reading the Rust Book piece-by-piece, and spent the first
        part of this morning finishing up chapters 19 and 20, which deal with
        advanced rust features and a final project for building a multi-threaded
        web server.
      </Paragraph>
      <Paragraph>
        My brain started dragging a bit towards then end, but unlike the past 8
        readings of the Rust book, I managed to make it through with a shallow
        understanding of almost all of the concepts discussed. I kept track of a
        few things that are "workable but still not fully solid" for me:
      </Paragraph>
      <ul>
        <li>
          Lifetimes in general. I think at this stage I'm familiar enough with
          lifetimes and the elision rules that I can spot simple issues, but I
          worry that when it comes to complicated ownership param issues,
          confusion can seep in and I'll get lost.
        </li>
        <li>
          Dynamic memory allocation via the dyn keyword, and when it's required
          to use <code>Box dyn</code>. I grasp that it involves needing to
          determine at runtime what type will be passed as the concrete type for
          the supplied generic. But I don't follow why dynamic dispatch is
          required for types implementing traits. It seems like, at runtime, the
          compiler could infer a concrete type based on usage, and treat the
          parameter the way it does with any generic (swapping out a concrete
          type at compile time). My best guess would be to avoid multiple
          versions of the same function for size reasons, but I'm not sure, and
          trust that people much smarter than me have good reasons for doing it.
        </li>
        <li>
          I think I need to read the chapter on closures another 10 or 15 times
          before the three available traits that a closure can implement will
          sink in.
        </li>
      </ul>
      <Paragraph>
        But, feeling good about that, I'm going to try diving into WASM. I'll
        report on my current struggles as I do so:
      </Paragraph>
      <SectionHeader>Cargo Generate</SectionHeader>
      <Paragraph>
        The{" "}
        <a href="https://rustwasm.github.io/docs/book/introduction.html">
          WASM tutorial
        </a>{" "}
        I'm following recommends starting with their project template,
        installable via cargo generate. I wasn't able to run cargo install
        cargo-generate because my mac's SSH agent wasn't running. I fixed that
        by running the following:
      </Paragraph>
      <code>
        eval `ssh-agent -s` <br />
        ssh-add <br />
        cargo ...
      </code>
      <Paragraph>
        With that, I was able to install cargo-generate but when I tried to
        clone a project, it failed with an authentication error.
      </Paragraph>
      <img alt="Displaying an authentication error in my terminal" src={auth} />
      <Paragraph>
        This turned out to be because of a weird configuration setting in my{" "}
        <code>.gitconfig</code> that rewrote <code>https://...</code> links as{" "}
        <code>git@</code>. Removing that, I was able to download the sample
        project properly.
      </Paragraph>
      <SectionHeader>Getting Started With WASM</SectionHeader>
      <Paragraph>
        With the tooling in place, I started the project. My editor complained a
        bit about some blocks of the code needing to be wrapped in an unsafe
        rust construct, but otherwise seemed to work well and properly call the
        example alert() from the initial project. I even did the extra credit
        and made it accept my name!
      </Paragraph>
      <img
        alt="Showing my web browser open to an alert with my name in it"
        src={name}
      />
    </div>
  );
}
