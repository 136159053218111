import { Paragraph } from "../ui";

export function OneYearLater() {
  return (
    <div>
      <Paragraph>
        Hey remember that time I wrote a single blog post about my desire to
        return to writing, and then forgot about it for a year?
      </Paragraph>
      <Paragraph>
        It's been such a wild year that I'd forgotten what this interface looked
        like, and I'm pleasantly surprised to find I still like it. This is
        maybe the first instance of me revisiting a program I've written and not
        recoiled in horror at how poorly it was made. This is actually not bad.
      </Paragraph>
      <Paragraph>
        So let's see... what's new? I've had personal changes that are too much
        to write about right now. With the exception of a certain pandemic, I've
        been deeply fortunate that most of those changes have been
        overwhelmingly positive.
      </Paragraph>
      <Paragraph>
        On the technical front, I have a new job that I love. I've picked up
        technical skills that I value and appreciate deeply. And whereas in the
        past I've simply expanded my ability to slap together increasingly
        complicated projects, recently I've started improving my sensibilities
        for planning out larger projects. I'm starting to realize that this
        skill is as critical as the ability to write clean code, or conceptually
        understand how to deploy your microservices to an autoscaling k8s
        cluster. Planning, structure, and acting on deliberate, clear
        instructions are how you build a thing that lasts. Or you get lucky, and
        it turns out the blogging interface is still pretty decent and has
        survived my latest "Do I really need this server for $5/mo?" purge.
      </Paragraph>
      <Paragraph>
        I'm glad it did. It's great to see this still works.
      </Paragraph>
    </div>
  );
}
