import { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import { contactItems } from "../contact";
import { Page, palette } from "../ui";

const Button = styled.button`
  box-sizing: border-box;
  background-color: transparent;
  border: 1px black solid;
  margin: 8px;
  padding: 8px 24px;
  font-size: 2rem;
  font-weight: 200;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
`;
const Table = styled.div``;
const Title = styled.h2`
  font-weight: 200;
  font-size: 3rem;
  box-sizing: border-box;
  margin: 0;
  padding: 24px;
`;

const ContactItem = styled.h4`
  box-sizing: border-box;
  font-size: 1.6rem;
  font-weight: 200;
  margin: 8px 0px;
  padding: 24px;
  max-width: 100vw;
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const Link = styled.a`
  color: black;
  text-decoration: none;
  box-sizing: border-box;
`;

function RobotPrompt({
  setIsRobot,
}: {
  setIsRobot: Dispatch<SetStateAction<boolean | undefined>>;
}) {
  return (
    <div>
      <Title>Are you a robot?</Title>
      <ButtonContainer>
        <Button onClick={() => setIsRobot(true)}>Yes</Button>
        <Button onClick={() => setIsRobot(false)}>No</Button>
      </ButtonContainer>
    </div>
  );
}

function ContactTable() {
  return (
    <Table>
      {contactItems.map((item) => {
        const text = `${item.label}: ${item.value}`;
        return (
          <div key={item.label}>
            {item.link ? (
              <Link href={item.link} target="_blank">
                <ContactItem>{text}</ContactItem>
              </Link>
            ) : (
              <ContactItem>{text}</ContactItem>
            )}
          </div>
        );
      })}
    </Table>
  );
}

export function ContactPage() {
  const [isRobot, setIsRobot] = useState<boolean | undefined>(undefined);
  const robotMsg = "Hello, my robot friend!";

  return (
    <Page backgroundColor={palette.CONTACTPAGE_BACKGROUND} fixed>
      <ContactInfo>
        {isRobot === undefined && <RobotPrompt setIsRobot={setIsRobot} />}
        <div>
          {isRobot === true && <Title>{robotMsg}</Title>}
          {isRobot !== undefined && <ContactTable />}
        </div>
      </ContactInfo>
    </Page>
  );
}
