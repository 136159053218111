interface ContactItem {
  label: string;
  value: string;
  featured: boolean;
  link?: string;
}

export const contactItems: ContactItem[] = [
  {
    label: "Email",
    featured: true,
    value: "Me aht RobertTownley.com",
  },
  {
    label: "GitHub",
    value: "RobertTownley",
    featured: false,
    link: "https://github.com/roberttownley/",
  },
  {
    label: "GoodReads",
    featured: false,
    value: "RobertTownley",
    link: "https://www.goodreads.com/user/show/60290592-robert-townley",
  },
  {
    label: "Twitter",
    featured: false,
    value: "@RobertTownley",
    link: "https://twitter.com/roberttownley/",
  },
];
