import styled from "styled-components";
import { Page } from "../ui";

import brush from "../assets/brush.svg";
import { MOBILE_NAVIGATION_HEIGHT } from "../navigation";

const BackgroundImage = styled.img`
  background-size: "auto";
  width: "100w";
  position: fixed;
  bottom: 0;
`;
const HomepageContainer = styled.div`
  background: linear-gradient(
    0deg,
    rgba(169, 0, 255, 0.9514006286108193) 0%,
    rgba(0, 31, 68, 1) 80%
  );

  height: calc(100vh - ${MOBILE_NAVIGATION_HEIGHT});
`;

const VanityItemsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  z-index: 100;
  box-sizing: border-box;
  overflow-y: none;

  @media (max-width: 768px) {
    padding-top: 32px;
    flex-direction: column;
    padding-bottom: 64px;
  }
`;
const VanityItem = styled.h2`
  color: white;
  z-index: 101;
  font-size: 42px;
  font-weight: 200;
`;

function VanityItems() {
  const items = ["Software Developer", "Content Creator", "Person With Ideas"];
  return (
    <VanityItemsContainer>
      {items.map((item) => (
        <VanityItem key={item}>{item}</VanityItem>
      ))}
    </VanityItemsContainer>
  );
}

export function Homepage() {
  return (
    <Page>
      <HomepageContainer>
        <BackgroundImage alt="Background silhouette of a forest" src={brush} />
        <VanityItems />
      </HomepageContainer>
    </Page>
  );
}
