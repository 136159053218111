import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Homepage } from "./pages/home";
import { AboutPage } from "./pages/about";
import { PortfolioPage } from "./pages/portfolio";
import { ContactPage } from "./pages/contact";
import { BlogListPage } from "./pages/bloglist";
import { BlogDetailPage } from "./pages/blogdetail";
import { ProjectsListPage } from "./pages/projectslist";

import { blogPosts } from "./blog";
import { projects } from "./projects";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/portfolio" element={<PortfolioPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/blog" element={<BlogListPage />} />
        {blogPosts.map((post) => (
          <Route
            path={`/blog/${post.slug}`}
            key={post.slug}
            element={<BlogDetailPage post={post} />}
          />
        ))}
        <Route path="/projects" element={<ProjectsListPage />} />
        {projects.map((project) => (
          <Route
            path={`/projects/${project.slug}`}
            element={project.component}
            key={project.slug}
          />
        ))}
        <Route path="/blog" element={<Homepage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
