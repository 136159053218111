import styled from "styled-components";
import { BlogPost } from "./types";

const Card = styled.a`
  padding: 16px;
  text-decoration: none;
  color: black;
  border: 1px black solid;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const Title = styled.h2`
  font-weight: 200;
  margin: 0;
  font-weight: 2.4rem;
  margin-bottom: 4px;
`;
const Subtitle = styled.h4`
  font-weight: 200;
  font-size: 1rem;
  margin: 0;
`;

export function BlogPreview({ post }: { post: BlogPost }) {
  return (
    <Card href={`/blog/${post.slug}/`}>
      <Title>{post.title}</Title>
      <Subtitle>Published on {post.publication_date.toLocaleString()}</Subtitle>
    </Card>
  );
}
