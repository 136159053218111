import { ReactNode } from "react";
import { CubeGoesUpGame } from "./cubegoesupgame";

interface Project {
  name: string;
  slug: string;
  component: ReactNode;
}

export const projects: Project[] = [
  {
    name: "Cube Goes Up Game",
    slug: "Cubegoesupgame",
    component: <CubeGoesUpGame />,
  },
];
