import { Box, Paragraph } from "../ui";

export function GoodbyeToUpside() {
  return (
    <Box>
      <Paragraph>
        On my first day, someone–I don’t remember who… that day was a BLUR–told
        me that a month at Upside is like a year somewhere else, both in terms
        of things you learn, and the connections you make. Like that person’s
        name, I also don’t remember much about the next two weeks. I just
        remember waking up later knowing Typescript, how airline inventory
        systems worked, and which of the three Chipotles near 1 Thomas Circle
        would be mobbed at lunchtime.
      </Paragraph>
      <Paragraph>
        I do remember my third week, because that was when the office shut down.
        Also the 5th week. That was the one where I found out Upside wanted me
        to work on a telecardiology mobile application. Then it’s back to a blur
        for three months but suddenly I know Kotlin and the Twilio API, and feel
        a deep and meaningful friendship with 7 people I’d never met in person.
      </Paragraph>
      <Paragraph>
        I remember rejoining Valkyrie, and worrying that I wouldn’t mesh as well
        with the team as I did with my Heartbeat Health crew. I was worried that
        my job was now to maintain two microservices for every term in aviation
        (one experience service and one vendor service). But I also remember
        just... sort of trusting that Upside would make things work out.
      </Paragraph>
      <Paragraph>
        The next memory blur was a pretty long one. I attribute it to pandemic
        malaise and new parent sleep deprivation. At the end of that one, months
        have passed. I’ve learned more about software development than I had in
        5 years at my previous company, and Valkyrie now feels like home. Also
        I’m a dad now, and whatever distant worries I had about “my company not
        supporting me through new parent life” have been replaced by “the slow
        burn that comes from knowing my coworkers want to see June more than
        me”.
      </Paragraph>
      <Paragraph>
        During normal times, getting that support from your company is
        empowering and restorative. But these weren’t normal times, and working
        for Upside got me through the pandemic. I spent months locked in a small
        apartment, worrying about how to keep COVID away from a pregnant partner
        and then an infant daughter. Even more so than Animal Crossing, this
        company’s understanding, enthusiasm, and devotion to each other was a
        shining light that made each day enjoyable. I’m a world-class forgetter,
        but I’ll never forget that.
      </Paragraph>
      <Paragraph>
        I deeply wish I’d had more than 18 months with you all, but I’m grateful
        for every second. Thank you to the leadership team for making Upside the
        best company I’ve ever worked for. Thank you to the unyinz, mobsters,
        86ers (NOT YOU), and darkly joyous team lead who made Valkyrie what it
        was. And from the bottom of my heart: thank you all for 1.5 years of
        cranking and vibing.
      </Paragraph>
      <Paragraph>The delight was mine.</Paragraph>
    </Box>
  );
}
