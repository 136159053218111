type Color = string;
interface Palette {
  HOMEPAGE_BACKGROUND: Color;
  ABOUTPAGE_BACKGROUND: Color;
  PORTFOLIOPAGE_BACKGROUND: Color;
  CONTACTPAGE_BACKGROUND: Color;
  BLOGPAGE_BACKGROUND: Color;

  LIGHT_TEXT: Color;
  DARK_TEXT: Color;
}

export const palette: Palette = {
  HOMEPAGE_BACKGROUND: "#001f44",
  ABOUTPAGE_BACKGROUND: "#EE8800",
  PORTFOLIOPAGE_BACKGROUND: "#0088EE",
  CONTACTPAGE_BACKGROUND: "#88aa88",
  BLOGPAGE_BACKGROUND: "#ffffff",

  DARK_TEXT: "#222222",
  LIGHT_TEXT: "#dddddd",
};
