import styled from "styled-components";

export const Box = styled.div``;

export const Link = styled.a`
  color: black;
  font-weight: 900;
  text-decoration: none;
`;
export const Paragraph = styled.p`
  line-height: 1.5;
  @media (max-width: 768px) {
    padding: 0px 16px;
  }
`;

export const SectionHeader = styled.h2`
  font-weight: 200;
`;
