import { Page } from "../ui";
import { ContentContainer } from "../ui/layouts";

import book from "../assets/book.jpg";
import fireworks from "../assets/fireworks.jpg";
import keyboard from "../assets/keyboard.jpg";
import lincoln from "../assets/lincoln.jpg";
import lightUpBook from "../assets/lightupBook.jpg";
import montreal from "../assets/montreal.jpg";
import nycSkyline from "../assets/nycSkyline.jpg";
import styled from "styled-components";

interface LifeStoryItem {
  label: string;
  background: string;
  fullWidth?: boolean;
}

const lifeStory: LifeStoryItem[] = [
  {
    label: "Life Story",
    background: book,
    fullWidth: true,
  },
  {
    label: "Born and raised in New York City",
    background: nycSkyline,
  },
  {
    label: "Went to business school in Montreal",
    background: montreal,
  },
  {
    label: "Had lots of ideas for companies",
    background: lightUpBook,
  },
  {
    label: "Learned to build websites for those companies",
    background: keyboard,
  },
  {
    label: "Became a decent web and mobile dev",
    background: fireworks,
  },
  {
    label: "Moved to Washington DC and started coding full time",
    background: lincoln,
  },
];

interface Skill {
  name: string;
  experience: number;
  comfort_level: number;
  personal_opinion: string;
}
interface SkillCategory {
  title: string;
  skills: Skill[];
}

const skillCategories: SkillCategory[] = [
  {
    title: "Programming Languages",
    skills: [
      {
        name: "Python",
        experience: 11,
        comfort_level: 9,
        personal_opinion: "❤️",
      },
      {
        name: "Javascript",
        experience: 13,
        comfort_level: 9,
        personal_opinion: "😨",
      },
      {
        name: "Typescript",
        experience: 3,
        comfort_level: 8,
        personal_opinion: "😌",
      },
      { name: "Rust", experience: 4, comfort_level: 5, personal_opinion: "🧠" },
      {
        name: "Kotlin",
        experience: 2,
        comfort_level: 4,
        personal_opinion: "😠",
      },
    ],
  },
  {
    title: "Frontend",
    skills: [
      {
        name: "React",
        experience: 4,
        comfort_level: 9,
        personal_opinion: "❤️",
      },
      {
        name: "React Native",
        experience: 3,
        comfort_level: 8,
        personal_opinion: "❤️📱",
      },
      { name: "Vue", experience: 4, comfort_level: 7, personal_opinion: "❤️" },
      { name: "Expo", experience: 4, comfort_level: 7, personal_opinion: "❤️" },
      {
        name: "ThreeJS",
        experience: 3,
        comfort_level: 6,
        personal_opinion: "❤️",
      },
    ],
  },
  {
    title: "Backend",
    skills: [
      {
        name: "FastAPI",
        experience: 2,
        comfort_level: 6,
        personal_opinion: "❤️👶",
      },
      {
        name: "Django",
        experience: 8,
        comfort_level: 9,
        personal_opinion: "❤️👴",
      },
      { name: "Node", experience: 5, comfort_level: 7, personal_opinion: "😐" },
      {
        name: "Express",
        experience: 3,
        comfort_level: 6,
        personal_opinion: "😐",
      },
    ],
  },
  {
    title: "DevOps",
    skills: [
      {
        name: "Linux",
        experience: 14,
        comfort_level: 6,
        personal_opinion: "❤️",
      },
      {
        name: "Docker",
        experience: 6,
        comfort_level: 7,
        personal_opinion: "🫙",
      },
      {
        name: "Kubernetes",
        experience: 4,
        comfort_level: 5,
        personal_opinion: "😵‍💫",
      },
      {
        name: "CI/CD",
        experience: 7,
        comfort_level: 5,
        personal_opinion: "😌",
      },
    ],
  },
  {
    title: "Databases",
    skills: [
      {
        name: "PostgreSQL",
        experience: 8,
        comfort_level: 5,
        personal_opinion: "😐",
      },
      {
        name: "MySQL",
        experience: 10,
        comfort_level: 5,
        personal_opinion: "😐",
      },
      {
        name: "ElasticSearch",
        experience: 5,
        comfort_level: 6,
        personal_opinion: "❤️",
      },
      {
        name: "MongoDB",
        experience: 7,
        comfort_level: 8,
        personal_opinion: "😐",
      },
      {
        name: "Neo4j",
        experience: 1,
        comfort_level: 3,
        personal_opinion: "🧠",
      },
    ],
  },
  {
    title: "Cloud & Vendors",
    skills: [
      { name: "AWS", experience: 5, comfort_level: 5, personal_opinion: "🤑" },
      { name: "GCP", experience: 3, comfort_level: 4, personal_opinion: "🤑" },
      {
        name: "Digital Ocean",
        experience: 5,
        comfort_level: 8,
        personal_opinion: "❤️",
      },
      {
        name: "Salesforce",
        experience: 2,
        comfort_level: 4,
        personal_opinion: "🤑",
      },
      {
        name: "Braze",
        experience: 2,
        comfort_level: 8,
        personal_opinion: "❤️",
      },
      {
        name: "Segment",
        experience: 1,
        comfort_level: 8,
        personal_opinion: "😐",
      },
      {
        name: "Datadog",
        experience: 4,
        comfort_level: 5,
        personal_opinion: "😐",
      },
      {
        name: "Sentry",
        experience: 7,
        comfort_level: 6,
        personal_opinion: "😐",
      },
    ],
  },
];

const Title = styled.h2<{ small?: boolean }>`
  box-sizing: border-box;
  color: white;
  font-size: ${(props) => (props.small ? "1.75rem" : "2.25rem")};
  font-weight: 200;
  margin: 0;
  padding: 16px;
`;

const LifeStorySection = styled.div`
  display: grid;
  max-width: 100vw;
  box-sizing: border-box;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const GridItem = styled(Title)<{ background: string }>`
  box-sizing: border-box;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)),
    url("${(props) => props.background}");
  background-position: center center;
  background-size: cover;
  height: 40vh;
  padding: 0;
`;
const FullWidthGridItem = styled(GridItem)`
  background-image: url("${(props) => props.background}");
  grid-column: span 3;
  @media (max-width: 768px) {
    grid-column: span 2;
  }
  box-sizing: border-box;
`;

const TechnicalSkillsSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const TechnicalSkillsTitle = styled(Title)`
  padding-top: 48px;
  box-sizing: border-box;
  background-color: black;
`;

const SkillsList = styled.div`
  color: white;
  justify-content: space-between;
  margin: 16px;
  padding: 8px;
  box-sizing: border-box;
  background-color: #424242;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const SkillRowDisplay = styled.div`
  font-size: 1.25rem;
  font-weight: 200;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  border-bottom: 1px solid rgba(81, 81, 81, 1);
  box-sizing: border-box;
  padding: 8px;
`;

const SkillRowHeaderDisplay = styled(SkillRowDisplay)`
  font-size: 18px;
`;

const Emoji = styled.span`
  font-size: 36px;
`;

function SkillRow({ skill }: { skill: Skill }) {
  return (
    <SkillRowDisplay>
      <span>{skill.name}</span>
      <span>{skill.experience}</span>
      <span>{skill.comfort_level}/10</span>
      <Emoji>{skill.personal_opinion}</Emoji>
    </SkillRowDisplay>
  );
}

function SkillRowHeader() {
  return (
    <SkillRowHeaderDisplay>
      <span>Name</span>
      <span>
        Years of
        <br />
        Experience
      </span>
      <span>
        Self-Assessed
        <br />
        Comfort Level
      </span>
      <span>Personal Opinion</span>
    </SkillRowHeaderDisplay>
  );
}

function SkillCategorySection({ category }: { category: SkillCategory }) {
  return (
    <div>
      <TechnicalSkillsTitle small>{category.title}</TechnicalSkillsTitle>
      <SkillsList>
        <SkillRowHeader />
        {category.skills.map((skill) => (
          <SkillRow key={skill.name} skill={skill} />
        ))}
      </SkillsList>
    </div>
  );
}

export function AboutPage() {
  return (
    <Page>
      <LifeStorySection>
        {lifeStory.map((item) => {
          const Container = item.fullWidth ? FullWidthGridItem : GridItem;
          return (
            <Container background={item.background}>
              <Title>{item.label}</Title>
            </Container>
          );
        })}
      </LifeStorySection>

      <ContentContainer fixed>
        <TechnicalSkillsTitle>Technical Skills</TechnicalSkillsTitle>
        <TechnicalSkillsSection>
          {skillCategories.map((category) => (
            <SkillCategorySection category={category} />
          ))}
        </TechnicalSkillsSection>
      </ContentContainer>
    </Page>
  );
}
