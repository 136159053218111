import { Paragraph } from "../ui";

export function HelloNewWorld() {
  return (
    <div>
      <Paragraph>
        A few years ago, I gave up one of my hobbies: spending multiple hours
        writing meandering meditations and posting them to Facebook. I did it
        because the ecosystem that my hobby fed into was, and remains, toxic. I
        worried that if I spent 4 hours lovingly crafting a funny story, it
        would be buried between an inflammatory political post and a Verizon ad
        trying to convince people that they're not that evil. People would read
        the things I wrote, but keep scrolling because someone with a degree in
        experimental psychology figured out a way to keep you glued to your
        screen until your phone's battery dies at 2:00am. Using a platform
        because it's the only way to keep in touch with your friends is one
        thing. But contributing to that platform with funny stories about
        setting my kitchen on fire was more of an endorsement than I could
        justify.
      </Paragraph>
      <Paragraph>
        In the intervening years since this feeling entered my head, my opinion
        hasn't changed, but my ability to act upon that opinion has changed
        greatly. I've now spent the majority of my professional life writing
        code every day, managing servers, and planning out large software
        projects. I've started three new side companies with people I trust.
        I've become a part of Washington DC's open source software movement. And
        I've learned from people much smarter than me how a large tech
        product—and a large tech company—works. All of the above has left me
        with a galvanized, arrogant enthusiasm that I can do something about
        this. I can do my part to provide an alternative platform. So in place
        of my Facebook hobby, that's what I've been doing for the past several
        months.
      </Paragraph>
      <Paragraph>
        With the help and advice of several people smarter than myself, I've
        started developing (or contributing to the development of) modern, open
        source alternatives to the platforms that have become such an important
        part of our day-to-day lives. I've also started work on a company that
        lets people without technical expertise use these alternatives for about
        $5-$10/month. If both parts of that effort take off, the mechanisms
        through which people and businesses interact online will be
        fundamentally changed for the better.
      </Paragraph>
      <Paragraph>
        So for the first time in a few years, I'm writing a meandering
        meditation. I'm not typing it into Facebook; I'm typing it into an open
        source blogging platform that I've been working on for the past several
        months. The platform isn't ready for other people to use, but it's
        getting there. It can't do everything I want it to do, but it's close.
        My writing muscles feel a bit atrophied, but they're warming up. And I
        have a lot of work to do before other people can enjoy the benefits of
        open source in the ways that I do, but that's changing.
      </Paragraph>
    </div>
  );
}
