import { PortfolioItem } from "./types";

import Belleayre from "../assets/portfolio/belleayre.jpg";
import Columbus from "../assets/portfolio/columbus.jpg";
import DachaDC from "../assets/portfolio/dachadc.png";
import DjangoInk from "../assets/portfolio/ink.png";
import Gamehook from "../assets/portfolio/gamehook.png";
import Happyly from "../assets/portfolio/happyly.jpeg";
import Impakt from "../assets/portfolio/impakt.png";
import NationalJournal from "../assets/portfolio/nationaljournal.png";
import Orphans from "../assets/portfolio/orphans.png";
import PearGroup from "../assets/portfolio/pear.png";
import PotteryChris from "../assets/portfolio/potteryChris.png";
import RocketMoneyImage from "../assets/portfolio/rocketmoney.png";
import UpsideImage from "../assets/portfolio/upside.png";

export const entries: PortfolioItem[] = [
  {
    title: "Rocket Money",
    subtitle: "Tech Lead Manager",
    image: RocketMoneyImage,
    link: "https://rocketmoney.com",
    bullets: [
      "Leads and manages a team of seven full-stack mobile developers and quality engineers",
      "Oversees improvements to the company growth engineering stack",
      "Responsible for the use and maintaneance of an in-house iterative A/B testing framework",
    ],
  },
  {
    title: "Upside Business Travel",
    subtitle: "Senior Full-Stack Developer",
    image: UpsideImage,
    bullets: [
      "Architected and built APIs for interacting with global flight inventory management systems",
      "Created user-facing web and mobile applications",
      "Managed ad-hoc teams of 3+ developers working on new product features",
      "Set up microservice monitoring and alerting infrastructure",
    ],
  },
  {
    title: "Atlantic Media",
    subtitle: "Principal Engineer, National Journal",
    image: NationalJournal,
    bullets: [
      "Worked for Atlantic Media for 5 years as the principal engineer behind NationalJournal.com",
      "Migrated decades of editorial content from legacy data sources",
      "Implemented various third-party integrations for marketing, business development, network science, and journalism",
      "Trained and mentored junior developers",
    ],
    link: "https://nationaljournal.com",
  },
  {
    title: "Pear Group",
    subtitle: "Lead Technologist",
    image: PearGroup,
    bullets: [
      "Co-founder and organizer of a small business collective",
      "Organizes meetings and coordinates development initiatves for paying clients and open-source projects",
    ],
  },
  {
    title: "Django Ink",
    subtitle: "Open Source Maintainer",
    link: "https://github.com/RobertTownley/ink",
    image: DjangoInk,
    bullets: ["Designed and built a CMS to use within the Django admin"],
  },
  {
    title: "Gamehook",
    subtitle: "Open Source Maintainer",
    link: "https://github.com/RobertTownley/gamehook",
    image: Gamehook,
    bullets: ["Built a react/typescript based game engine on top of ThreeJS"],
  },
  {
    title: "Happyly",
    subtitle: "Web & Mobile Developer",
    image: Happyly,
    bullets: [
      "Built a website and mobile app for a curated children's activities platform",
      "Made a CMS for curators to use as they enter data on activities in their city",
    ],
    link: "https://www.happyly.com/",
  },
  {
    title: "Impakt",
    subtitle: "Web & Chrome Extension Developer",
    image: Impakt,
    bullets: [
      "Built a Chrome extension to inform users about ethical issues as they shop online",
      "Designed and deployed a data pipeline for ingesting ethical incident data",
      "Created a CMS for moderators to manually edit/correct app data",
    ],
  },
  {
    title: "Dacha DC",
    subtitle: "Web Development",
    image: DachaDC,
    bullets: [
      "Built a custom Wordpress template based on designer specifications",
    ],
    link: "https://dachadc.com/",
  },
  {
    title: "Pottery Chris",
    subtitle: "Web Developer",
    image: PotteryChris,
    bullets: [
      "Built a website for a family friend's pottery business using Squarespace",
      "Photographed existing inventory and wrote product descriptions",
    ],
    link: "http://www.potterychris.com/",
  },
  {
    title: "Belleayre Lodge",
    subtitle: "Web Development",
    image: Belleayre,
    bullets: [
      "Built the website for a ski lodge in Upstate NY using Squarespace",
      "Integrated the new website into a legacy reservation management system",
    ],
  },
  {
    title: "The Orphans",
    subtitle: "Photography",
    image: Orphans,
    bullets: ["Organized and conducted photo shoots for an actors' collective"],
  },
  {
    title: "Columbus or Bust!",
    subtitle: "Photography",
    image: Columbus,
    bullets: [
      "Organized and conducted photo and music video shoots for a NYC-based folk band",
    ],
  },
];
