import { DateTime } from "luxon";
import { BlogPost } from "./types";

import { HelloNewWorld } from "./posts/HelloNewWorld";
import { GettingBetterAtVim } from "./posts/GettingBetterAtVim";
import { OneYearLater } from "./posts/OneYearLater";
import { SolarPunk } from "./posts/SolarPunk";
import { FunWithWASM } from "./posts/FunWithWASM";
import { GoodbyeToUpside } from "./posts/GoodbyeToUpside";

export const blogPosts: BlogPost[] = [
  {
    title: "Goodbye To Upside",
    component: <GoodbyeToUpside />,
    publication_date: DateTime.fromISO("2021-08-18"),
    slug: "goodbye-to-upside",
  },
  {
    component: <FunWithWASM />,
    publication_date: DateTime.fromISO("2021-08-02"),
    slug: "fun-with-wasm",
    title: "Fun With WASM",
  },
  {
    component: <GettingBetterAtVim />,
    publication_date: DateTime.fromISO("2021-07-30"),
    slug: "getting-better-at-vim",
    title: "Getting A Little Better At Vim",
  },
  {
    component: <SolarPunk />,
    publication_date: DateTime.fromISO("2021-07-19"),
    slug: "solar-punk",
    title: "Solar Punk Note",
  },
  {
    component: <OneYearLater />,
    publication_date: DateTime.fromISO("2021-06-03"),
    slug: "one-year-later",
    title: "One Year Later",
  },
  {
    component: <HelloNewWorld />,
    publication_date: DateTime.fromISO("2020-03-22"),
    slug: "another-world-3",
    title: "Hello, New World!",
  },
];
